<template>

  <div class="heSuan">

    <div class="top">
      <div class="projectName">项目名称</div>
      <div class="covid">新冠病毒抗原检测</div>
      <div class="tips">备注：
        <p>1.抗原检测价格为2元。</p>
        <p>2.发票打印可在取检后15分钟后在自助缴费机打印，若有疑问请联系现场工作人员。</p>
      </div>
    </div>
    <div class="choose"
         v-if="patNameArray.length > 0">
      <van-field readonly
                 clickable
                 label="选择就诊人"
                 :value="patName"
                 @click="patNamePickerShow = true"
                 right-icon="arrow-down" />
      <van-popup v-model="patNamePickerShow"
                 round
                 position="bottom">
        <van-picker show-toolbar
                    :columns="patNameList"
                    @cancel="patNamePickerShow = false"
                    @confirm="patNameConfirm" />
      </van-popup>

    </div>
    <div class="point">
      <div class="user">选择核酸项目</div>
      <div class="item">
        <van-radio-group v-model="samplingType">
          <van-cell-group>
            <van-cell title="抗原检测（2.00）元"
                      clickable
                      data-name="1"
                      @click="onClickType">
              <van-radio slot="right-icon"
                         name="1"
                         checked-color="#67C23A" />
            </van-cell>
            <!-- <van-cell title="混采（2.80）元"
                      clickable
                      data-name="2"
                      @click="onClickType">
              <van-radio slot="right-icon"
                         name="2"
                         checked-color="#67C23A" />
            </van-cell> -->
          </van-cell-group>
        </van-radio-group>
      </div>

    </div>

    <!-- <div class="point">
      <div class="user">选择院区</div>
      <div class="item">
        <van-radio-group v-model="hospitalCode">
          <van-cell-group>
            <van-cell title="总院"
                      clickable
                      data-name="1"
                      @click="onClickHospitalCode">
              <van-radio slot="right-icon"
                         name="1"
                         checked-color="#67C23A" />
            </van-cell>
            <van-cell title="石花分院"
                      clickable
                      data-name="2"
                      @click="onClickHospitalCode">
              <van-radio slot="right-icon"
                         name="2"
                         checked-color="#67C23A" />
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>

    </div> -->
    <div class="point"
         v-if="!patNameArray.length">
      <!-- 证件号 -->
      <van-field v-model="cardNo"
                 label="身份证号"
                 placeholder="请输入身份证号"
                 input-align="right"
                 @input="getCardNo"
                 @blur="getAge"
                 class="input" />
      <!-- 姓名 -->
      <van-field v-model="userName"
                 label="姓名"
                 placeholder="请输入姓名"
                 input-align="right"
                 @input="getName"
                 class="input" />
      <!-- 性别 -->
      <div class="sex">
        <van-radio-group :value="sexRadio"
                         direction="horizontal">
          性别:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <van-radio name="1"
                     @click="radioChange(1)">男性</van-radio>
          <van-radio name="2"
                     @click="radioChange(2)">女性</van-radio>
        </van-radio-group>
      </div>
      <!-- 出生日期 -->
      <van-field readonly
                 clickable
                 label="出生日期"
                 :value="birthday"
                 @click="datePickerShow = true"
                 placeholder="请选择"
                 right-icon="arrow-down"
                 class="picker" />
      <van-popup v-model="datePickerShow"
                 round
                 position="bottom">
        <van-datetime-picker v-model="currentDate"
                             type="date"
                             :min-date="minDate"
                             :max-date="maxDate"
                             :formatter="formatter"
                             @confirm="dateConfirm"
                             @cancel="dateCancel" />
      </van-popup>
      <van-field v-model="telephone"
                 label="手机号"
                 placeholder="请输入手机号"
                 input-align="right"
                 @input="getTelephone"
                 type="tel"
                 maxlength="11"
                 class="input" />
    </div>
    <div class="btn">
      <van-button type="primary"
                  block
                  @click="orderBtn"
                  v-if="patNameArray.length > 0">确认</van-button>
      <van-button type="primary"
                  block
                  @click="fileClick"
                  v-if="!patNameArray.length">确认</van-button>
    </div>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
import { stringify } from 'querystring';
export default {
  name: "covid",
  data () {
    return {
      overlayShow: false,
      showPopup: false,
      arrayTreat: [],
      name: '',
      patCardNo: '',
      patCardType: '',
      radio: '1',
      samplingType: '1',
      hospitalCode: '1',
      price: [],
      solo: '',
      mix: '',
      patName: '',
      patNamePickerShow: false,
      patNameList: [],
      patNameArray: [],
      patNameIndex: 0,
      cardNo: "",
      userName: "",
      sexRadio: '1',
      birthday: "",
      datePickerShow: false,
      // 出生日期
      minDate: new Date(1800, 0, 1),
      maxDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      currentDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      telephone: "",
    }
  },
  created () {
    if (window.localStorage.getItem("openId") === null) {
      this.getCode();
    } else {
      this.getMembers();
    }

  },
  methods: {
    getAge () {
      if (this.cardNo.trim() === "" || this.cardNo.length !== 18) {
        this.getData();
        this.maxDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        );
        this.currentDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        );
        this.age = 0;
      } else {
        this.sub(this.cardNo);
      }
    },
    sub (str) {
      let date = new Date();
      let year = date.getFullYear();
      this.age = parseInt(year) - parseInt(str.substring(6, 10));
      this.birthday =
        str.substring(6, 10) +
        "-" +
        str.substring(10, 12) +
        "-" +
        str.substring(12, 14);
      this.currentDate = new Date(
        str.substring(6, 10),
        str.substring(10, 12) - 1,
        str.substring(12, 14)
      );

    },
    // 获取当天日期
    getData () {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      let today = year + "-" + month + "-" + strDate;
      this.birthday = today;
    },
    fileClick () {
      if (this.cardNo === "") {
        this.$toast.fail("证件号不能为空");
      } else if (/[\W]/g.test(this.cardNo)) {
        this.$toast.fail("证件号含有特殊符号，请重新输入");
      } else if (
        !/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
          this.cardNo
        )
      ) {
        this.$toast.fail("证件号格式有误，请重新输入");
      } else if (this.userName === "") {
        this.$toast.fail("姓名不能为空");
      } else if (this.userName.length > 6) {
        this.$toast.fail("姓名长度不能超过六位，请重新输入");
      } else if (!/^[\u4e00-\u9fa5a-zA-Z]+$/.test(this.userName)) {
        this.$toast.fail("姓名含有特殊符号，请重新输入");
      } else if (this.telephone === "") {
        this.$toast.fail("手机号不能为空");
      } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.telephone)) {
        this.$toast.fail("手机号有误，请重新输入");
      } else {
        this.file();
      }
    },
    //建档
    async file () {
      this.$toast.loading({
        duration: 0,
        message: "建档中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
        name: this.userName,
        telephone: this.telephone,
        sex: this.sexRadio,
        isAdult: "1",
        cardType: "01",
        cardNo: this.cardNo,
        birthday: this.birthday,
        isSelf: "01",
        isHS: "1",
        sourceType: "1",
        status: "0"
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/add",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.orderBtn(res.data)
      } else {
        this.$toast.fail(res.msg);
      }
    },
    getTelephone (value) {
      this.telephone = value;
    },
    formatter (type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return `${val}日`;
    },
    dateFormat (dateData) {
      let date = new Date(dateData);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let time = y + "-" + m + "-" + d;
      let today = new Date().getFullYear();
      this.age = today - y;
      return time;
    },
    dateConfirm (value) {
      this.birthday = this.dateFormat(value);
      this.datePickerShow = false;
    },
    dateCancel () {
      this.datePickerShow = false;
    },
    radioChange (num) {
      this.sexRadio = num.toString()
    },
    getName (value) {
      this.userName = value;
    },
    getCardNo (value) {
      this.cardNo = value;
    },
    onClickType (event) {
      const { name } = event.currentTarget.dataset;
      this.samplingType = name
    },
    onClickHospitalCode (event) {
      const { name } = event.currentTarget.dataset;
      this.hospitalCode = name
    },
    // 获取code
    getCode () {
      let local = window.location.href;
      let appid = "wxcdae494f7e9422e7";
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent
        (local)
        }&response_type=code&scope=snsapi_base&state=123&#wechat_redirect`;
      // window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcdae494f7e9422e7&redirect_uri=http://tssywx.tssrmyy.com&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
      let str = window.location.href;
      let code = str.split("code=")[1].split("&")[0];
      this.code = code
      this.login(code);
    },
    //获取就诊人列表
    async getMembers () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      var data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/getAllUseInfo",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.patNameArray = res.data;
        for (var i = 0; i < this.patNameArray.length; i++) {
          this.patNameList.push(res.data[i].name + '(' + res.data[i].pat_card_no + ')');
        }
        this.patName = this.patNameList[this.patNameIndex];
        this.patCardNo = this.patNameArray[this.patNameIndex].pat_card_no
      }
    },
    formatTime (num) {
      var now = new Date();
      var nowTime = now.getTime();
      var oneDayTime = 24 * 60 * 60 * 1000;
      var ShowTime = nowTime + num * oneDayTime;
      var myDate = new Date(ShowTime);
      var y = myDate.getFullYear(); //年
      var m = (myDate.getMonth() + 1) < 10 ? ("0" + (myDate.getMonth() + 1)) : myDate.getMonth() + 1; //月
      var d = myDate.getDate() < 10 ? ("0" + myDate.getDate()) : myDate.getDate(); //日
      return [y, m, d].map(this.padStartConvert).join('-');
    },
    padStartConvert (n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    },
    async orderBtn (cardNo) {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });

      const { data: res } = await jsonPost(
        "/wxapp/appointmentRecord/record/lockReg",
        {
          beginTime: "22:00",
          deptCode: "0113010101",
          deptName: "门诊内科",
          doctorCode: "8188",
          doctorName: "马芬芳",
          endTime: "23:00",
          hospitalId: 100,
          medicalCardNo: this.patCardNo || cardNo,
          openId: window.localStorage.getItem("openId"),
          regFee: "10",
          scheduleDate: this.formatTime(0),
          timeFlag: "1",
          patName: this.name || this.userName,
          nucleicType: "4",
          // hospitalCode: this.hospitalCode
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        setTimeout(() => {
          this.$router.push({
            path: "/outpatient/detail",
            query: {
              orderNum: res.data.orderNum,
              patCardNo: this.patCardNo || cardNo,
              patCardType: '1'
            }
          });
        }, 500);
      } else {
        this.$toast.fail(res.msg);

      }
    },
    patNameConfirm (value) {
      console.log(value);
      this.patName = value;
      this.patNameIndex = this.patNameList.findIndex(item => {
        return item === value;
      });
      this.patNamePickerShow = false;
      this.patCardNo = this.patNameArray[this.patNameIndex].pat_card_no
    },
    nClickType (event) {
      const { name } = event.currentTarget.dataset;
      this.samplingType = name
    },
  }
}

</script>

<style lang='less' scoped>
/deep/ .van-field {
  color: #000 !important;
  font-size: 36px;
  .van-field__control {
    text-align: end !important;
  }
}
page {
  width: 100%;
  height: 100%;
  background-color: #edecf0;
}
.heSuan {
  width: 100%;
  height: 150vh;
  font-size: 33px;
  background-color: #edecf0;
  .top {
    background-color: #fff;
    div {
      padding: 10px;
    }
    .projectName {
      font-weight: 600;
      color: #0852b8;
    }

    .covid {
      padding-left: 50px;
      font-weight: 600;
    }
    .tips {
      color: #c0bec1;
      padding-left: 50px;
    }
  }
  .choose {
    background-color: #fff;
    margin-top: 40px;
    height: 100px;
    line-height: 40px;
  }
  .point {
    color: #1f2430;
    background-color: #fff;
    margin-top: 50px;
    // div {
    //   padding: 10px;
    // }

    .user {
      font-weight: 600;

      color: #0852b8;
    }
    .van-cell__title {
      font-size: 33px;
      padding-left: 50px;
    }
    .sex {
      font-size: 35px;
      padding: 0.26667rem 0.42667rem;
    }
  }

  .btn {
    margin-bottom: 55px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    .van-button {
      font-size: 33px;
      font-weight: bold;
    }
  }
}
</style>